@import 'src/scss/vars';

.reactSyntaxHighlighter {
  position: relative;
  z-index: 10;
  background: $darkBlue;
  border-radius: $borderRadius;
  box-sizing: border-box;

  .dots {
    padding: 1rem;
    box-sizing: border-box;
    background: $darkGrey-5;
    border-radius: $borderRadius $borderRadius 0 0;

    .dot {
      cursor: pointer;
      width: 1.1rem;
      height: 1.1rem;
      border-radius: 100%;
      margin-right: .7rem;
    }
  }

  .highlighterHeader {
    position: sticky;
    box-sizing: border-box;
    padding: 1.5rem 2.25rem;
    top: calc(#{$headerHeight - 1px});
    background-color: rgba($darkBlue, 0.95);
    border-radius: $borderRadius;

    @media (max-width: 992px) {
      top: calc(#{$mobileHeaderHeight - 1px});
    }
    .config {
      margin-left: 1rem !important;

      input {
        width: 2.1rem !important;
        height: 1.2rem !important;
      }
      .form-check-input:checked {
        background-color: $darkBlue-5 !important;
      }
    }
  }

  .buttons {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0.65rem;
    top: -0.2rem;
    transition: 0.3s;
    display: flex;
    align-items: center;
    z-index: 2;

    @media (max-width: 992px) {
      top: 0.6rem !important;
      opacity: 1 !important;
      pointer-events: auto !important;
    }

    .copy,
    .raw {
      background: $darkBlue-2;
      padding: 0.4rem 0.6rem;
      box-sizing: border-box;
      border-radius: $borderRadius;
      transition: 0.2s;

      &:hover {
        opacity: 0.7;
        transition: 0.2s;
      }
    }

    .raw {
      margin-right: 1rem;
    }
  }

  &:hover {
    .buttons {
      pointer-events: auto;
      opacity: 1;
      top: 0.6rem;
      transition: 0.3s;
    }
  }

  .highlighter {
    padding: 0 2.25rem 1rem !important;
    box-sizing: border-box;

    &.textWhite {
      span {
        color: $white;
      }
    }

    &.minLineNumberWidth {
      .linenumber {
        min-width: 3.25em !important;
      }
    }

    > code {
      > span {
        display: block;
        transition: 0.15s;

        &:hover {
          background: $darkBlue-2;
          transition: 0.15s;
        }

        .linenumber {
          color: #565674 !important;
        }
      }
    }
  }

  pre {
    background: transparent !important;
    padding: 0 !important;
  }

  &.terminal {
    background: $darkBlue-4;

    .highlighterHeader {
      background: $darkBlue-4;
      position: relative;
      top: 0;
      padding: 1rem 2rem;
    }

    .highlighter {
      padding: 0 1.5rem 1rem !important;

      @media (max-width: 576px) {
        padding: 0 0 1rem !important;
      }
    }
  }
}
