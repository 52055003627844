@import 'src/scss/vars';

.dateCalendar {
  max-height: calc(100vh - #{$headerHeight} - 20px);
  overflow-y: auto;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  border-radius: 0.475rem;

  @media (max-width: 580px) {
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  }

  .rdrCalendarWrapper {
    background-color: $darkBlue;

    .rdrDateDisplayWrapper {
      display: none;
    }
    .rdrMonthAndYearWrapper {
      padding-top: 0;

      button {
        background-color: darken(rgba($darkBlue, 0.8), 2%);

        i {
          border-color: transparent $white transparent transparent;
        }
      }
      .rdrNextButton {
        i {
          border-color: transparent transparent transparent $white;
        }
      }
      .rdrMonthAndYearPickers {
        select {
          color: $white;
          border: none;
          outline: none;
          box-shadow: none;
          padding: 0;
          width: 95px;
          height: 35px;
          text-align-last: center;

          &:hover {
            background-color: darken(rgba($darkBlue, 0.8), 2%);
          }

          option {
            background-color: darken(rgba($darkBlue, 0.8), 2%);
            border: none;
            outline: none;
            box-shadow: none;
            display: flex;
            justify-content: center;
            text-align: center;
            margin: auto;
            text-align-last: center;
            text-align-all: center;
          }
        }
      }
    }
    .rdrMonths {
      .rdrWeekDays {
        span {
          color: $darkGrey-2;
        }
      }
      .rdrDays {
        .rdrDay {
          span {
            color: $white;
          }
        }
        .rdrDayPassive {
          span {
            color: $darkGrey-2;
          }
        }
        .rdrDayDisabled {
          background-color: transparent;

          span {
            color: $darkGrey-2;
          }
        }
        .rdrStartEdge,
        .rdrEndEdge {
          color: $blue !important;
        }
      }
    }
  }
}
