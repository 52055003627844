.toolbar {
  .renderModal {
    position: fixed;
    display: flex;
    height: auto;
    width: auto;
    padding: 0;
    top: 70px;
    right: -100%;
    pointer-events: none;
    transition: 1s;
    z-index: -1;

    &.show {
      z-index: 10;
      opacity: 1;
      right: 30px;
      pointer-events: auto;
      transition: 0.25s;

      @media (max-width: 992px) {
        right: 15px;
      }
    }
  }
  .calendarWrapper {
    position: fixed;
    padding: 0;
    width: auto;
    top: 70px;
    right: 30px;
    z-index: 11;

    @media (max-width: 992px) {
      right: 15px;
    }
  }
}
