@import 'src/scss/vars';

.contentTable {
  &.active {
    transition: .2s;
    &:hover {
      transform: scale(1.02);
      transition: .2s;
    }
  }

  .headerSticky {
    position: sticky;
    top: calc(#{$headerHeight} - 1px);
    background-color: rgba($darkBlue, 0.95);
    border-radius: $borderRadius;

    @media (max-width: 992px) {
      top: $mobileHeaderHeight;
    }
  }

  .recharts-active-dot {
    transform: translateZ(10px);
  }

}
