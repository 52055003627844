.guildValidation {
  .items {
    display: flex;
    flex-wrap: wrap;

    .item {
      width: 32%;
      margin-right: 2%;

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    &.fullItems {
      .item {
        width: 100%;
        margin-right: 0;
      }
    }
  }

  .loading {
    width: 32%;
    margin-right: 2%;
    margin-bottom: 1.5rem;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}
