@import "src/scss/vars";

.timelineSlider {
  .categories {
    @media (max-width: 575px) {
      padding: 0 2rem !important;
    }

    > div {
      transition: .2s;

      &.disable {
        transform: none !important;
        cursor: default !important;
      }

      &:first-of-type {
        padding-left: 0 !important;
      }

      &:last-of-type {
        padding-right: 0 !important;
      }

      @media (max-width: 575px) {
        p {
          display: none;
        }
      }

      &:hover {
        transform: scale(1.1);
        transition: .2s;
      }

      &:active {
        transform: scale(.95);
        transition: .2s;
      }
    }

    .icon {
      position: relative;

      > span {
        > svg {
          width: 3.5rem;
          height: 3.5rem;

          @media (max-width: 768px) {
            width: 3rem;
            height: 3rem;
          }

          @media (max-width: 576px) {
            width: 2.3rem;
            height: 2.3rem;
          }
        }
      }
      .status {
        position: absolute;
        bottom: -.6rem;
        right: -.6rem;

        @media (max-width: 576px) {
          svg {
            width: 1.5rem !important;
            height: 1.5rem !important;
          }
        }
      }
    }
  }

  .validationButton {
    width: 11.5rem !important;
    height: 2.8rem;

    > div {
      height: 100%;

      > div {
        height: 100%;
        padding: 0 !important;

        > div {
          height: 100%;
        }
      }
    }
  }

  select {
    font-size: .925rem;
    height: 100%;
  }

  .form-check-input {
    height: 1.35rem !important;
    width: 2.3rem !important;
  }

  .form-select {
    width: 16%;
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .recharts-cartesian-axis-tick-line {
    transform: translateY(-6px);
  }

  .slider {
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all .3s;

    &.show {
      overflow: initial;
      height: auto;
      opacity: 1;
      transition: all .3s;
    }
  }

  .date {
    position: absolute;
    bottom: -24px;
    width: max-content;
  }
  .trackWrapper {
    width: 100%;
    position: absolute;
    z-index: 2;
    height: 9px;
    display: flex;
    padding: 0 5px;
    align-items: center;

    .track {
      height: 2px;
      width: 100%;
      border-radius: 6px;
    }
  }
  .thumb {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $blue-2;
    outline: none;
    transition: 0.1s;

    .counter {
      width: max-content;
      opacity: 0;
      transition: 0.2s;
      position: absolute;
      top: -30px;
      border-radius: 4px;
      padding: 4px;
      background-color: $blue-2;
    }

    &:focus,
    &:hover,
    &:active {
      .counter {
        opacity: 1;
        transition: 0.2s;
      }
    }
  }

  .calendarButton {
    padding: .5rem 1rem !important;
  }

  .calendarWrapper {
    position: absolute;
    z-index: 5;
    left: 50%;
    transform: translateX(-50%);
    top: 120%;
  }

  .bottomContainer {
    @media (max-width: 580px) {
      .calendarContainer {
        position: initial !important;

        .calendarWrapper {
          top: 92%;
        }
      }
    }

    @media (max-width: 469px) {
      .validationButton {
        margin: 1rem 0 0 2rem;
      }

    }
  }
}
