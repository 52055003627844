@import "src/scss/vars";

.dateFilter {
  width: auto !important;

  .switches {
    display: flex;
    align-items: center;
    width: auto;
    justify-content: center;
    background-color: $darkBlue;
    border-radius: 2rem;
    padding: 2px;
    box-sizing: border-box;
    position: relative;

    .activeItem {
      position: absolute;
      background-color: $darkGrey-5;
      transition: 0.4s;
      box-sizing: border-box;
      border-radius: 2rem;
      width: 30px;
      height: 88%;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    .switch {
      position: relative;
      z-index: 2;
      padding: 0.5rem 1rem;

      p {
        transition: 0.45s;
      }
    }
  }

  .range {
    margin-left: 0.8rem;
    transition: 0.2s;

    span {
      margin: 0 0.2rem 0.1rem;
    }
    &.hideRange {
      display: none !important;
      transition: 0.2s;
    }
  }

  .calendarButton {
    margin-left: 1.5rem;

    .calendarWrapper {
      position: absolute;
      top: 45px;
      right: 0;
    }
  }

  &.mobileDateFilter {
    flex-wrap: wrap;

    .switches {
      width: 100%;
      justify-content: space-between;

      .switch {
        padding: 0.5rem 0.8rem;
        margin: 0 !important;
      }
    }
    .calendarButton {
      width: 100%;
      margin-left: 0;
      margin-top: 0.5rem;

      div {
        justify-content: center;
      }
    }
  }

  .buttonLoading {
    margin-left: 1.5rem;
  }
}
