@import "src/scss/vars";

.nodeTester {
  position: relative;
  min-height: 48rem;
  height: calc(100vh - 180px);
  transition: overflow .1s;
  overflow: hidden;

  @media (max-width: 992px) {
    height: calc(100vh - 140px);
  }

  .headerSection {
    position: absolute;
    transition: .4s;
    bottom: 4.5rem;
  }

  .goButton {
    width: 8rem;
    margin-left: 1.5rem;

    div {
      height: 100%;
      padding: 0 !important;

      .btn {
        padding: 1.2rem 1rem !important;

        p {
          font-size: 1.3rem;
        }
      }
    }
  }

  input {
    background: $darkGrey-5 !important;
    padding: 1.2rem 1rem;
    font-size: 1.3rem;

    &::placeholder {
      color: $darkGrey;
    }
  }

  .highlighterWrapper {
    position: absolute;
    width: 80%;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    transition: .4s;

    pre {
      min-height: 33rem;
      height: calc(100vh - 15rem - 180px);
      overflow-y: scroll;

      @media (max-width: 992px) {
        height: calc(100vh - 15rem - 140px);
      }
    }

    @media (max-width: 576px) {
      width: calc(100% - 4.5rem);
    }
  }

  .firstSection {
    position: absolute;
    z-index: 11;
    width: 80%;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    transition: .4s;

    @media (max-width: 768px) {
      width: calc(100% - 4.5rem);
    }
  }

  &.defaultPosition {
    transition: .5s;

    .firstSection {
      top: 2rem;
      transform: translate3d(-50%, 0, 0);
      transition: .4s;

      .headerSection {
        bottom: 120%;
        transition: .4s;
      }
    }

    .highlighterWrapper {
      top: 8rem;
      transition: .4s;
    }
  }
}
