// Colors

$darkBlue: #1e1e2d
$darkBlue-2: #282a3d
$darkBlue-3: #1a1a27
$darkBlue-4: #1b1b29
$darkBlue-5: #5372AB
$green: #198754
$teal: #20c997
$blue: #009ef6
$blue-2: #3699ff
$yellow: #ffc107
$red: #dc3545
$white: #ffffff
$black: #000
$grey: #cdcdde
$darkGrey: #92929f
$darkGrey-2: #6d6d80
$darkGrey-3: #2b2b40
$darkGrey-4: #565674
$darkGrey-5: #323248
$darkGrey-6: #4d4d53
$purple: #6f42c1
$indigo: #6610f2
$cyan: #0dcaf0



// Markup

$headerHeight: 120px
$mobileHeaderHeight: 110px
$borderRadius: 0.475rem



// Other

$mobile: 767px



// Mixins

@mixin mobile
  @media (max-width: #{ $mobile })
    @content


@mixin desktop
  @media (min-width: #{ $mobile })
    @content
