@import 'src/scss/vars';

.endpointTable {
  .card-header {
    position: sticky;
    top: calc(#{$headerHeight - 1px});
    background-color: rgba($darkBlue, 0.95) !important;
    border-radius: $borderRadius;
    z-index: 5;

    @media (max-width: 992px) {
      top: calc(#{$mobileHeaderHeight - 1px});
    }
  }
  table {
    position: relative;

    * {
      border-collapse: collapse !important;
    }

    tr {
      border-style: hidden !important;

      th {
        border-style: hidden !important;
      }
    }
    .borderLine {
      position: absolute;
      width: 100%;
      border-bottom: 2px dashed $darkBlue-2;
      z-index: 2;
    }
  }
}
