@import 'src/scss/vars';

.endpointRow {
  position: relative;

  td {
    border: none !important;
    border-bottom-width: 0 !important;
    border-collapse: initial !important;
  }
}
