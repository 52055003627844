@import 'src/scss/vars';

.rangeSlider {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .leftValue,
  & .rightValue {
    input {
      width: auto;
      max-width: 100%;
      outline: none;
      border: none;
      background-color: $darkGrey-3;
      text-align: center;
      border-radius: 0.45em;
      padding: 0.3rem 0 0.25rem;
      color: $grey;
    }
  }

  .leftValue {
    left: 0;
  }

  .rightValue {
    right: 0;
  }

  .trackWrapper {
    width: auto;
    flex: auto;
    height: 30px;
    display: flex;
    align-items: center;
    z-index: 2;

    .track {
      height: 4px;
      width: 100%;
      border-radius: 6px;
    }
  }
  .thumb {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $blue-2;
    outline: none;
    transition: 0.1s;

    .counter {
      width: max-content;
      opacity: 0;
      transition: 0.2s;
      position: absolute;
      top: -30px;
      border-radius: 4px;
      padding: 4px;
      background-color: $blue-2;
    }

    &:hover,
    &:active {
      .counter {
        opacity: 1;
        transition: 0.2s;
      }
    }
  }
}
