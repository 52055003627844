@import 'src/scss/vars';

.guildRow {
  .validationIconTrue {
    stroke: $white;
  }
  .validationIconFalse {
    stroke: $darkGrey-6;
  }

  @media (max-width: 1100px) {
    .avatar {
      width: calc(100% / 6);
      flex: 0 0 auto;
    }
    .guildInformation {
      flex: 0 0 auto;
      width: calc(100% / 1.5);
      text-align: center;

      div {
        justify-content: center;
      }
    }
    .space {
      flex: 0 0 auto;
      width: calc(100% / 6);
    }
    .element {
      margin-top: 1.5rem;
    }
  }
}
