@import 'src/scss/vars';

.filterModal {
  display: flex !important;
  max-height: calc(100vh - #{$headerHeight} - 20px);
  overflow-y: auto;
  z-index: 2;

  .hideContent {
    position: absolute;
    top: -100%;
    opacity: 0;
    pointer-events: none;
    transition: .1s;
  }

  .select {
    padding: 0 10px;
    font-size: 1.1rem;
    height: 38px;
    font-weight: normal;
    background-size: 14px;
    background-position-x: calc(100% - 14px);
  }

  .multiSelect {
    .dropdown-container {
      background: transparent;
      border: none !important;
      box-shadow: none !important;

      .item-renderer {
        align-items: center;
      }

      .dropdown-heading {
        background: $darkBlue-4;
        border-radius: $borderRadius;

        svg {
          color: $darkGrey-2;
        }
      }

      .panel-content {
        background: $darkBlue-4;
        border-color: transparent;
        box-shadow: none;
        border-radius: $borderRadius;

        .search {
          input {
            color: $white
          }
        }

        .options {
          label {
            cursor: default !important;

            &:hover {
              background: rgba($darkGrey-3, .7) !important;
            }

            &:focus {
              background: none;
            }

            &.selected {
              background: $darkBlue-4;
            }
          }
          .form-check-label {
            cursor: default !important;
          }
        }
      }

      .dropdown-content {
        position: relative;
      }
    }
  }
}

.maskLayer {
  position: absolute;
  width: 100vw;
  height: 100vh;
  right: -30px;
  top: -135px;
  z-index: 1;

  @media (max-width: 992px) {
    right: -15px;
    top: -125px;
  }
}
