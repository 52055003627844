@import 'src/scss/vars';

.guildValidation {
  .validationTable {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
    width: 1px;

    .notificationWrapper {
      position: relative;
      width: 100%;
      height: 0;
      transition: .5s;

      .notification {
        position: fixed;
        top: 0;
        opacity: 0;
        z-index: 100;
        width: calc(100% - 265px);
        pointer-events: none;
        right: 0;
        border-radius: 0 0 $borderRadius $borderRadius;
        transition: .5s;

        @media (max-width: 992px) {
          width: 100%;
        }
      }

      &.show {
        height: 46px;
        transition: .5s;

        .notification {
          opacity: 1;
          pointer-events: auto;
          top: 120px;
          transition: .5s;

          @media (max-width: 992px) {
            top: 110px;
          }
        }

        @media (max-width: 992px) {
          height: 60px;
        }

        @media (max-width: 462px) {
          height: 75px;
        }
      }
    }
  }
  .single {
    padding-right: 0 !important;
  }

  .rightContainer {
    flex: 0 0 auto;
    width: auto;
    padding: 0;

    .section {
      max-width: 230px;
      position: sticky !important;
      top: calc(#{$headerHeight} + 30px);

      > .notificationWrapper {
        position: relative;
        width: auto;
        height: 0;
        transition: .5s;

        .notification {
          position: absolute;
          top: -300px;
          opacity: 0;
          z-index: 100;
          pointer-events: none;
          transition: .5s;
        }

        &.show {
          height: 115px;
          transition: .5s;

          .notification {
            opacity: 1;
            pointer-events: auto;
            top: 0;
            transition: .5s;

            @media (max-width: 992px) {
              top: 110px;
            }
          }
          @media (max-width: 576px) {
            height: 5.2rem;
          }

          @media (max-width: 483px) {
            height: 6.5rem;
          }
        }
      }
    }

    .sidebar {
      &.indent {
        .sideScrollspyNav {
          max-height: calc(100vh - #{$headerHeight} - 195px);
          transition: .5s;
        }
      }

      > div {
        > div {
          padding-bottom: 0 !important;
        }
      }
    }
  }

  .mobileSidebar {
    position: fixed;
    flex: none;
    padding: 0;
    width: auto;
    right: -100%;
    z-index: 2;
    top: calc(#{$headerHeight} + 30px);
    transition: 1.2s;

    @media (max-width: 992px) {
      top: calc(#{$mobileHeaderHeight} + 15px);
    }

    &.showSidebar {
      right: 30px;
      transition: 0.4s;

      @media (max-width: 992px) {
        right: 1rem;
      }
    }

    &.indent {
      top: calc(#{$headerHeight} + 90px);
      transition: .5s;

      @media (max-width: 992px) {
        top: calc(#{$mobileHeaderHeight} + 90px);
        transition: .5s;
      }

      @media (max-width: 576px) {
        top: calc(#{$mobileHeaderHeight} + 90px);
        transition: .5s;
      }

      @media (max-width: 462px) {
        top: calc(#{$mobileHeaderHeight} + 105px);
        transition: .5s;
      }
    }
  }

  .buttonShow {
    position: fixed;
    top: calc(#{$headerHeight} + 48px);
    z-index: 2;
    right: 28px;
    width: auto;
    padding: 0;
    transition: 0.3s;

    @media (max-width: 992px) {
      top: calc(#{$mobileHeaderHeight} + 34px);
      right: 1rem;
    }

    @media (max-width: 768px) {
      top: calc(#{$mobileHeaderHeight} + 30px);
    }

    &.hideButton {
      right: -20px;
      transition: 0.3s;
      pointer-events: none;
    }

    &.indent {
      top: calc(#{$headerHeight} + 90px);
      transition: .5s;

      @media (max-width: 992px) {
        top: calc(#{$mobileHeaderHeight} + 90px);
        transition: .5s;
      }

      @media (max-width: 462px) {
        top: calc(#{$mobileHeaderHeight} + 105px);
        transition: .5s;
      }
    }

    span,
    svg {
      width: 30px;
      height: 30px;
    }
  }
}
