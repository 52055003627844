@import 'src/scss/vars';

.mapTooltip {
  position: absolute;
  padding: 0.5rem 0.8rem 0.5rem 0.7rem;
  background: $darkBlue-3;
  z-index: 1000;
  border-radius: $borderRadius;

  &.interactive {
    z-index: 100;
    margin: -4px;
    background: $darkBlue-3;
    max-height: 320px;
    overflow: auto;
    cursor: pointer;
    scrollbar-color: $darkGrey-2 transparent !important;

    > div {
      transition: 0.15s;
      margin-bottom: 0.2rem;

      &:hover {
        background: $darkBlue-2;
        transition: 0.15s;
      }
    }

    &::-webkit-scrollbar-thumb {
      background: $darkGrey-2;
    }

    p {
      margin-left: 0.3rem;
    }

    .numbering {
      user-select: none;
      min-width: 1.6em;
      text-align: right;
      margin: 0 0.6rem 0 0;
      font-family: 'Operator Mono', 'Fira Code', Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    }
  }
}
