@import 'src/scss/vars';

.nodeFinder {
  position: relative;

  .userLocation {
    position: absolute;
    left: 10px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    border-radius: $borderRadius;
    background: $darkBlue;
    top: 554px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    transition: 0.2s;

    &:hover {
      transform: scale(1.08);
      transition: 0.2s;
    }

    &:active {
      transform: scale(0.95);
      transition: 0.2s;
    }
  }

  .board {
    position: absolute;
    top: 0;
    right: 0;

    @media (max-width: 440px) {
      width: 300px !important;
    }
  }
}
