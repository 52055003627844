@import 'src/scss/vars';

.sideScrollspyNav {
  height: auto;
  overflow-y: auto;
  max-height: calc(100vh - #{$headerHeight} - 75px);
  transition: .5s;

  &.mobile {
    box-shadow: 0 0 2rem 1rem rgba(0, 0, 0, 0.3);
  }

  svg {
    width: 1.5rem !important;
    height: 1.5rem !important;
  }

  .active {
    .element {
      background-color: $darkBlue-2;
    }
  }

  .element {
    transition: 0.2s;

    &:hover {
      background-color: $darkBlue-2;
      transition: 0.2s;
    }
  }
}
