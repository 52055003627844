@import 'src/scss/vars';

.map {
  #deckgl-wrapper {
    user-select: auto !important;
    border-radius: $borderRadius;
    overflow: hidden;

    .mapboxgl-ctrl-bottom-left {
      display: none;
    }
  }
  .hint {
    position: absolute;
    display: flex;
    width: fit-content;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 10;
    padding: 2rem;
    opacity: 1;
    background: rgba($darkBlue-3, 0.85);
    border-radius: $borderRadius;
    pointer-events: none;
    transition: 1s;
  }
  .mapHeader {
    position: sticky;
    padding: 1.5rem 2.25rem;
    z-index: 2;
    top: calc(#{$headerHeight - 1px});
    background-color: rgba($darkBlue, .95);
    border-radius: $borderRadius;

    @media (max-width: 992px) {
      top: calc(#{$mobileHeaderHeight - 1px});
    }
  }
}
