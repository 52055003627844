@import 'src/scss/vars';

.ContentRow {
  border-top: 1px solid $darkBlue-2 !important;

  .statusIcon {
    font-size: 1.7rem;
    width: 6rem;
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }

  @media (max-width: 1240px) {
    .contentRowHeader {
      width: 22%;
    }
    .statusIcon {
      padding-right: 1.5rem !important;
    }
  }
  @media (max-width: 576px) {
    .contentRowHeader {
      width: 25%;
    }
    .statusIcon {
      width: 4rem;
      padding-right: 0 !important;
    }
  }
  @media (max-width: 420px) {
    .contentRowHeader {
      width: 30%;
    }
  }
  @media (max-width: 360px) {
    .contentRowHeader {
      width: 33%;
    }
  }

  .mobileContent {
    padding-left: 2.25rem !important;
  }
}
