@import 'src/scss/vars';

.dateRangeCalendar {
  max-height: calc(100vh - #{$headerHeight} - 20px);
  overflow-y: auto;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  border-radius: 0.475rem;

  .rdrCalendarWrapper {
    background-color: $darkBlue;

    .rdrDateDisplayWrapper {
      background-color: darken(rgba($darkBlue, 0.8), 2%);

      span {
        background-color: transparent;
        box-shadow: none;

        &.rdrDateDisplayItemActive {
          border-color: $blue;
        }

        input {
          color: $white;
        }
      }
    }
    .rdrMonthAndYearWrapper {
      button {
        background-color: darken(rgba($darkBlue, 0.8), 2%);

        i {
          border-color: transparent $white transparent transparent;
        }
      }
      .rdrNextButton {
        i {
          border-color: transparent transparent transparent $white;
        }
      }
      .rdrMonthAndYearPickers {
        select {
          color: $white;
          border: none;
          outline: none;
          box-shadow: none;
          text-align: center;

          &:hover {
            background-color: darken(rgba($darkBlue, 0.8), 2%);
          }

          option {
            background-color: darken(rgba($darkBlue, 0.8), 2%);
            border: none;
            outline: none;
            box-shadow: none;
            text-align: center;
            justify-content: center;
          }
        }
      }
    }
    .rdrMonths {
      .rdrWeekDays {
        span {
          color: $darkGrey-2;
        }
      }
      .rdrDays {
        .rdrDay {
          span {
            color: $white;
          }
        }
        .rdrDayPassive {
          span {
            color: $darkGrey-2;
          }
        }
        .rdrDayDisabled {
          background-color: transparent;

          span {
            color: $darkGrey-2;
          }
        }
        .rdrStartEdge,
        .rdrEndEdge {
          color: $blue !important;
        }
      }
    }
  }
}
