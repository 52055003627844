@import 'src/scss/vars';

.infoBoard {
  background: $darkBlue;
  border-radius: $borderRadius;
  width: 100%;
  display: block !important;

  .range {
    height: auto;
    opacity: 1;
    transition: 0.25s;

    &.hide {
      opacity: 0;
      height: 0;
      pointer-events: none;
      transition: 0.25s;
    }
  }
}
