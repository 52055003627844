@import 'vars';

.customSelect {
  border: none !important;

  > div {
    background: $darkGrey-5 !important;
    border: none !important;
    box-shadow: none;
    border-radius: $borderRadius;
    overflow: hidden;
    padding: 0;
    cursor: pointer;
  }

  > .p__menu {
    padding: 0;

    > .p__menu-list {
      padding: 0;

      > .p__option{
        background: $darkGrey-5;
        cursor: pointer;

        &:hover {
          background: $darkGrey-4;

          p {
            color: $white !important;
          }
        }
      }

      > .p__option--is-selected {
        background: $blue !important;

        p {
          color: $white !important
        }
      }
    }
  }
  img {
    width: 1.6rem;
    height: 1.6rem;
  }
}

.fallbackLogo {
  width: 100%;
  height: 100%;
  background: $darkGrey-5;
  border-radius: 100%;
}

.card-hover {
  transition: 0.2s;

  &:hover {
    transform: scale(1.02);
    transition: 0.2s;
  }
}

.button-active {
  transition: 0.2s;

  &:active {
    transform: scale(0.95);
    transition: 0.2s;
  }
}

.no-pointer-events {
  pointer-events: none;
}

.blur-blue {
  @supports (backdrop-filter: blur(9px)) {
    backdrop-filter: blur(9px);
    background-color: rgba($darkBlue, 0.9) !important;
  }
}

.blur-darkBlue {
  @supports (backdrop-filter: blur(7px)) {
    backdrop-filter: blur(7px);
    background-color: darken(rgba($darkBlue, 0.8), 2%) !important;
  }
}

.darkLoading {
  background-color: darken(rgba($darkBlue, 0.8), 2%) !important;
  background-image: linear-gradient(
    90deg,
    darken(rgba($darkBlue, 0.8), 2%),
    $darkBlue,
    darken(rgba($darkBlue, 0.8), 2%)
  ) !important;
}

.fw-500 {
  font-weight: 500;
}

@for $i from 0 through 20 {
  .ml-#{$i} {
    margin-left: 1rem * $i;
  }
  .pl-#{$i} {
    padding-left: 1rem * $i;
  }
  .mr-#{$i} {
    margin-right: 1rem * $i;
  }
  .pr-#{$i} {
    padding-right: 1rem * $i;
  }
}


.no-mobile {
  @include mobile {
    display: none !important;
  }
}

.no-desktop {
  @include desktop {
    display: none !important;
  }
}

img.covered {
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

img {
  &.contained {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &.filled {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  &.fullWidth {
    width: 100%;
    height: auto;
    max-height: 100%;
  }

  &.fullHeight {
    width: auto;
    height: 100%;
    max-width: 100%;
  }
}

.transition {
  transition: .2s;
}
